const options = [
  {
    label: "Administração",
    leftIcon: "ion-android-options",
    ability: ["admin", "user.index", "attachment"],
    children: [
      {
        key: "abilities/list",
        label: "Permissões",
        ability: ["admin"],
      },
      {
        key: "users/list",
        label: "Usuários",
        ability: ["admin", "user.index"],
      },
      {
        label: "Anexos",
        key: "attachments",
        ability: ["attachment"],
      }
    ],
  },
  {
    label: "Empresa",
    leftIcon: "ion-briefcase",
    ability: ["admin"],
    children: [
      {
        key: "companies/list",
        label: "Empresas",
        ability: ["admin"],
      },
      {
        label: "Empresas e Adquirentes",
        key: "acquirer-company",
        ability: ["financial.acquirer-company"],
      },
      {
        label: 'Contas de Liquidação',
        key: 'settlement-account',
        ability: ['settlement-account'],
      },
    ],
  },
  {
    label: "Bicos combustível",
    leftIcon: "ion-android-car",
    key: "fuel-nozzle",
    ability: ["fuel-nozzle.actions"],
  },
  {
    label: "Compras",
    leftIcon: "ion-bag",
    ability: ["purchases.purchase-orders", "storage"],
    children: [
      {
        key: "storage",
        label: "Depósito de combustível",
        ability: ["storage"],
      },
      {
        key: "purchases/order/list",
        label: "Pedidos de Compra",
        ability: ["purchases.purchase-orders"],
      },
    ],
  },
  {
    label: "Conciliação",
    leftIcon: "ion-android-options",
    ability: ["admin", "financial.card-conciliation"],
    children: [
      {
        label: 'Conciliação de cartões',
        key: 'card-conciliation',
        ability: ['financial.card-conciliation'],
      },
      {
        label: 'Conciliação de transações',
        key: 'transaction-conciliation',
        ability: ["financial.conciliation-params"],
      },
    ],
  },
  {
    label: "Consultas",
    leftIcon: "ion-clipboard",
    ability: [
      "financial.statements",
      "financial.acquirer-transactions",
      "financial.system-movements"
    ],
    children: [
      {
        label: "Consultar extrato",
        key: "financial/statements",
        ability: ["financial.statements"],
      },
      {
        label: "Transações adquirentes",
        key: "financial/acquirer-transactions",
        ability: ["financial.acquirer-transactions"],
      },
      {
        label: "Transações sistema",
        key: "financial/system-movements",
        ability: ["financial.system-movements"],
      },
    ],
  },
  {
    label: "Fechamento Caixa",
    leftIcon: "ion-ios-paper",
    ability: [
      "cash-conciliation",
      "cash-conciliation.movement-type",
      "cash-conciliation.solicitation.analyze",
      "cash-conciliation.solicitation",
      "smart-pos-terminal",
    ],
    children: [
      {
        label: "Conferência Caixa",
        key: "cash-conciliation",
        ability: ["cash-conciliation"],
      },
      {
        label: "Motivos de movimentação",
        key: "movement-type",
        ability: ["cash-conciliation.movement-type"],
      },
      {
        label: "Solicitações pendentes",
        key: "cash-conciliation/solicitation/pending",
        ability: ["cash-conciliation.solicitation.analyze"],
      },
      {
        label: "Solicitar fechamento",
        key: "cash-conciliation/solicitation/conferences",
        ability: ["cash-conciliation.solicitation"],
      },
      {
        label: "Terminais smartPOS",
        key: "smart-pos-terminal",
        ability: ["smart-pos-terminal.actions"],
      },
      {
        label: "Vinculos de pagamentos",
        key: "cash-conciliation-movement-reason-map",
        ability: ["cash-conciliation.solicitation.analyze"],
      },
    ],
  },
  {
    label: "Financeiro",
    leftIcon: "ion-clipboard",
    ability: [
      "financial.borderos",
      "settlement-account",
      "financial.bank-balances",
      "financial.acquirer-transactions",
      "financial.card-fee",
      'financial.bank-slip'
    ],
    children: [
      {
        label: "Compensar Borderôs",
        key: "financial/borderos",
        ability: ["financial.borderos"],
      },
      {
        label: 'Contas de Baixa',
        key: 'settlement-account',
        ability: ['settlement-account'],
      },
      {
        label: "Saldos Bancários",
        key: "financial/bank/balance",
        ability: ["financial.bank-balances"],
      },
      {
        label: 'DDA',
        key: 'bank-slips',
        ability: ['financial.bank-slip'],
      }
    ],
  },
  {
    label: "Fiscal",
    leftIcon: "ion-clipboard",
    ability: ["fiscal.sage-integration"],
    children: [
      {
        label: "Importação de lançamentos",
        key: "fiscal/sage-integration",
        ability: ["fiscal.sage-integration"],
      },
    ],
  },
  {
    label: "Fluxo de Tarefa",
    leftIcon: "ion-ios-people",
    ability: ["task-flow.tasks", "task-flow.my-tasks"],
    children: [
      {
        key: "task-groups/list",
        label: "Grupos de Tarefas",
        ability: ["task-flow.tasks"],
      },
      {
        key: "tasks/list",
        label: "Tarefas",
        ability: ["task-flow.tasks"],
      },
      {
        key: "my-tasks",
        label: "Minhas Tarefas",
        ability: ["task-flow.my-tasks"],
      },
    ],
  },
  {
    label: "Importações",
    leftIcon: "ion-android-settings",
    ability: ["setting-column-mapping", "financial.download-transactions"],
    children: [
      {
        label: "Arquivos",
        key: "financial-transactions/column-mapping/import",
        ability: ["import-file-column-mapping"],
      },
      {
        label: "Transações",
        key: "financial/download-transactions",
        ability: ["financial.download-transactions"],
      },
    ],
  },
  {
    label: "Integrações",
    leftIcon: "ion-android-settings",
    ability: ["dominio-integration"],
    children: [
      {
        label: "Dominio",
        key: "integration/dominio-launch-calculation",
        ability: ["dominio-integration"],
      },
      {
        label: " Ponto Mais",
        key: "integration/ponto-mais-export-payroll",
        ability: ["ponto-mais-export-payroll"],
      },
    ],
  },
   {
    label: "Painel de Controle",
    leftIcon: "ion-monitor",
    ability: ["budget"],
    children: [
      {
        key: "budget/list",
        label: "Orçamentos",
        ability: ["budget"],
      }
    ],
  },
];
export default options;
